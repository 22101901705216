
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { Component, Vue } from 'vue-property-decorator'
import { appName } from '@/env'
import mainModule from '@/store/main'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class Login extends Vue {
  public email = ''
  public appName = appName

  public cancel(): void {
    this.$router.back()
  }

  public submit(): void {
    console.log('Password recowery is not ready')
    mainModule.actionAddNotification({
      notification: { content: 'Function is not ready', color: 'error' },
      timeout: 0
    })
    // mainModule.actionPasswordRecovery({ email: this.email })
  }
}
